import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconOffer.module.css';

const IconOffer = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      viewBox="0 0 512.000000 512.000000"
      width="14"
      height="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M832 4940 c-237 -62 -422 -259 -462 -490 -7 -46 -10 -646 -8 -2075
l3 -2010 21 -40 c46 -88 156 -137 251 -112 29 8 172 88 360 200 171 103 316
187 323 187 6 0 173 -97 372 -215 360 -215 361 -215 420 -215 32 0 74 7 94 15
19 8 185 105 370 216 222 133 342 199 354 196 11 -3 159 -90 331 -193 296
-179 314 -188 370 -192 80 -6 137 17 188 74 22 26 43 63 50 89 8 31 11 434 11
1399 l0 1355 298 3 297 3 57 27 c80 37 152 107 191 187 l32 66 0 520 0 520
-24 74 c-66 201 -236 357 -447 411 -75 20 -117 20 -1728 19 -1592 0 -1654 -1
-1724 -19z m2783 -317 c-5 -10 -18 -43 -29 -73 -21 -55 -21 -55 -26 -2001 l-5
-1947 -239 143 c-132 79 -260 149 -285 157 -57 16 -162 16 -217 0 -23 -7 -189
-100 -368 -207 l-325 -195 -293 176 c-161 97 -306 184 -323 194 -43 26 -131
50 -181 50 -97 0 -139 -18 -392 -170 l-247 -149 -3 1891 c-1 1209 1 1904 8
1927 24 91 100 170 193 202 49 17 132 18 1396 19 1275 0 1343 -1 1336 -17z
m659 -17 c60 -29 103 -73 138 -139 23 -42 23 -46 26 -511 3 -429 1 -470 -14
-487 -16 -18 -36 -19 -281 -19 l-263 0 0 493 c0 473 1 494 21 537 33 73 93
126 169 151 46 15 147 3 204 -25z"
        />
        <path
          d="M1295 3736 c-120 -53 -120 -228 -1 -286 43 -20 50 -21 724 -18 673 3
681 3 709 24 15 11 38 36 51 54 46 68 18 176 -58 219 -37 21 -43 21 -716 21
-541 -1 -685 -3 -709 -14z"
        />
        <path
          d="M1318 2888 c-50 -13 -104 -74 -113 -127 -9 -53 25 -126 74 -158 l34
-23 691 0 691 0 36 24 c97 66 91 210 -11 270 -34 21 -49 21 -700 23 -418 1
-679 -3 -702 -9z"
        />
        <path
          d="M1292 2003 c-127 -62 -110 -254 26 -293 25 -8 251 -10 709 -8 663 3
672 3 700 24 100 75 98 201 -6 271 l-34 23 -681 0 c-608 0 -685 -2 -714 -17z"
        />
      </g>
    </svg>
  );
};

IconOffer.defaultProps = {
  rootClassName: null,
  className: null,
};

IconOffer.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconOffer;
